$primaryColor: #2196F3;
$primaryTextColor: #ffffff;

$logoBgColor: #42A5F5;
$topbarBgColor: #2196F3;
$topbarIconColor: #ffffff;
$topbarIconHoverBgColor: #64b5f6;
$lightMenuitemActiveTextColor: #2196F3;
$darkMenuitemActiveTextColor: #2196F3;
$horizontalMenuitemActiveBgColor: #2196F3;
$horizontalMenuitemActiveTextColor: #ffffff;
$badgeBgColor: #2196F3;
$badgeTextColor: #ffffff;

@import '../sass/layout/_layout';