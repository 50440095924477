.layout-wrapper {
    .layout-menu-container {
        position: fixed;
        height: 100%;
        z-index: 999;

        .layout-menu-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: auto;
        }

        .layout-menu-logo {
            background-color: $logoBgColor;

            button {
                display: block;
                text-align: left;
                height: 60px;
                padding-left: 12px;
                width: 100%;

                img {
                    vertical-align: middle;
                    height: 30px;
                }
            }
        }

        .layout-profile {
            &.layout-profile-active {
                .layout-profile-icon {
                    transform: rotate(-180deg);
                }
            }

            .layout-profile-button {
                padding: 20px 12px;
                display: block;
                position: relative;
                width: 100%;
                @include clearfix();
                transition: background-color $transitionDuration;

                img {
                    width: 36px;
                    display: inline-block;
                }

                .layout-profile-userinfo {
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 8px;

                    .layout-profile-name {
                        display: inline-block;
                        font-weight: 700;
                    }

                    .layout-profile-role {
                        display: block;
                    }
                }

                .layout-profile-icon {
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    margin-top: -8px;
                    font-size: 16px;
                    transition: transform $transitionDuration;
                }
            }

            .layout-profile-menu {
                list-style: none;
                padding: 0 12px 0;
                margin: 0;
                overflow: hidden;

                &.layout-profile-menu-enter {
                    max-height: 0;
                }

                &.layout-profile-menu-enter-active {
                    overflow: hidden;
                    max-height: 500px;
                    transition: max-height 1s ease-in-out;
                }

                &.layout-profile-menu-enter-done {
                    transform: none;
                }

                &.layout-profile-menu-exit {
                    max-height: 500px;
                }

                &.layout-profile-menu-exit-active {
                    overflow: hidden;
                    max-height: 0;
                    transition: max-height 400ms cubic-bezier(0.86, 0, 0.07, 1);
                }

                li {
                    button {
                        display: block;
                        padding: 8px 4px;
                        text-align: left;
                        width: 100%;
                        border-radius: $borderRadius;
                        transition: background-color $transitionDuration;

                        i {
                            margin-right: 8px;
                            vertical-align: middle;
                        }

                        span {
                            font-weight: 700;
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }

                    &:last-child {
                        padding-bottom: 14px;
                    }
                }
            }
        }

        .layout-menu {
            list-style-type: none;
            padding: 14px 12px 120px 12px;
            margin: 0;
            height: 100%;

            .layout-root-menuitem {
                ul {
                    > li {
                        ul {
                            overflow: hidden;
                            &.layout-submenu-container-enter {
                                max-height: 0;
                            }

                            &.layout-submenu-container-enter-active {
                                overflow: hidden;
                                max-height: 1000px;
                                transition: max-height 1s ease-in-out;
                            }

                            &.layout-submenu-container-enter-done {
                                transform: none;
                            }

                            &.layout-submenu-container-exit {
                                max-height: 1000px;
                            }

                            &.layout-submenu-container-exit-active {
                                overflow: hidden;
                                max-height: 0;
                                transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
                            }
                        }
                    }
                }
            }

            li {
                a,
                .p-link {
                    display: block;
                    width: 100%;
                    padding: 8px 0;
                    position: relative;
                    cursor: pointer;
                    user-select: none;
                    border-radius: $borderRadius;
                    transition: background-color $transitionDuration;

                    .layout-menuitem-icon {
                        margin-right: 6px;
                        vertical-align: middle;
                    }

                    .layout-menuitem-text {
                        font-weight: 700;
                        vertical-align: middle;
                    }

                    .layout-submenu-toggler {
                        position: absolute;
                        right: 0px;
                        top: 50%;
                        font-size: 16px;
                        margin-top: -8px;
                        transition: transform $transitionDuration;
                    }

                    .menuitem-badge {
                        position: absolute;
                        display: block;
                        right: 10px;
                        height: 20px;
                        width: 44px;
                        line-height: 20px;
                        top: 50%;
                        font-size: 12px;
                        margin-top: -10px;
                        font-weight: 700;
                        background-color: #2196F3;
                        color: #ffffff;
                        text-align: center;
                        border-radius: 10px;
                    }

                    &.rotated-icon {
						.layout-menuitem-icon {
							transform: rotate(90deg);
						}
					}
                }

                &.active-menuitem {
                    > a,
                    > .p-link {
                        .layout-submenu-toggler {
                            transform: rotate(-180deg);
                        }
                    }
                }

                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                    li {
                        a,
                        .p-link {
                            padding-left: 4px;
                        }

                        li {
                            a,
                            .p-link {
                                padding-left: 16px;
                            }

                            li {
                                a,
                                .p-link {
                                    padding-left: 32px;
                                }

                                li {
                                    a,
                                    .p-link {
                                        padding-left: 48px;
                                    }

                                    li {
                                        a,
                                        .p-link {
                                            padding-left: 64px;
                                        }

                                        li {
                                            a,
                                            .p-link {
                                                padding-left: 80px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        ul {
                            overflow: hidden;
                        }
                    }
                }
            }

            > li {
                margin-bottom: 16px;

                > a,
                > .p-link {
                    display: none;
                }

                > div {
                    margin-bottom: 4px;
                }
            }

            .layout-menu-tooltip {
                display:none;
                padding: 0 5px;
                position: absolute;
                left: 61px;
                top: 17px;
                line-height: 1;

                .layout-menu-tooltip-text {
                   padding: 6px 8px;
                   font-weight: 700;
                   background-color: $tooltipBgColor;
                   color: $tooltipTextColor;
                   min-width: 75px;
                   white-space: nowrap;
                   text-align: center;
                    border-radius: $borderRadius;
                    box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
                }

                .layout-menu-tooltip-arrow {
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-color: transparent;
                    border-style: solid;
                    top: 50%;
                    left: 0;
                    margin-top: -5px;
                    border-width: 5px 5px 5px 0;
                    border-right-color: $tooltipBgColor;
                }
            }
        }

        .p-link {
            border-radius: 0;
        }
    }

    &.layout-menu-light {
        .layout-menu-container {
            background-color: $menuBgColor;

            .layout-menu-wrapper {
                border-right: $menuBorder;
            }

            .layout-profile {
                border-bottom: $menuSeparator;

                .layout-profile-button {
                    .layout-profile-name {
                        color: $menuitemTextColor;
                    }

                    .layout-profile-role {
                        color: $menuitemTextColor;
                    }

                    .layout-profile-icon {
                        color: $menuitemIconColor;
                    }

                    &:hover {
                        background-color: $menuitemHoverBgColor;
                    }
                }

                .layout-profile-menu {
                    button {
                        color: $menuitemTextColor;

                        i {
                            color: $menuitemIconColor;
                        }

                        &:hover {
                            background-color: $menuitemHoverBgColor;
                        }
                    }
                }
            }

            .layout-menu {
                li {
                    a,
                    .p-link {
                        .layout-menuitem-text {
                            color: $menuitemTextColor;
                        }

                        .layout-menuitem-icon,
                        .layout-submenu-toggler {
                            color: $menuitemIconColor;
                        }

                        &:hover {
                            background-color: $menuitemHoverBgColor;
                        }

                        &.active-route {
                            .layout-menuitem-text, .layout-menuitem-icon {
                                color: $lightMenuitemActiveTextColor;
                            }
                        }

                        &.router-link-active, &.router-link-exact-active {
                            .layout-menuitem-text, .layout-menuitem-icon {
                                color: $lightMenuitemActiveTextColor;
                            }
                        }
                    }
                }

                > li {
                    > div {
                        color: $rootMenuitemTextColor;
                    }
                }
            }
        }
    }

    &.layout-menu-dark {
        .layout-menu-container {
            background-color: $darkMenuBgColor;

            .layout-menu-wrapper {
                border-right: $darkMenuBorder;
            }

            .layout-profile {
                border-bottom: $darkMenuSeparator;

                button {
                    .layout-profile-name {
                        color: $darkMenuitemTextColor;
                    }

                    .layout-profile-role {
                        color: darken($darkMenuitemTextColor, 15%);
                    }

                    .layout-profile-icon {
                        color: $darkMenuitemIconColor;
                    }

                    &:hover {
                        background-color: $darkMenuitemHoverBgColor;
                    }
                }

                .layout-profile-menu {
                    button {
                        color: $darkMenuitemTextColor;

                        i {
                            color: $darkMenuitemIconColor;
                        }

                        &:hover {
                            background-color: $darkMenuitemHoverBgColor;
                        }
                    }
                }
            }

            .layout-menu {

                li {
                    a,
                    .p-link {
                        .layout-menuitem-text {
                            color: $darkMenuitemTextColor;
                        }

                        .layout-menuitem-icon,
                        .layout-submenu-toggler {
                            color: $darkMenuitemIconColor;
                        }

                        &:hover {
                            background-color: $darkMenuitemHoverBgColor;
                        }

                        &.active-route {
                            .layout-menuitem-text, .layout-menuitem-icon {
                                color: $darkMenuitemActiveTextColor;
                            }
                        }

                        &.router-link-active, &.router-link-exact-active {
                            .layout-menuitem-text, .layout-menuitem-icon {
                                color: $darkMenuitemActiveTextColor;
                            }
                        }
                    }
                }

                > li {
                    > div {
                        color: $darkMenuRootMenuitemTextColor;
                    }
                }
            }
        }
    }

    .layout-main {
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;

        .layout-content {
            padding: 16px;
            flex: 1 1 0;
        }
    }
}

@media screen and (min-width: 897px) {
    .layout-wrapper {

        &.layout-static {
            .layout-menu-container {
                width: 250px;
                left: 0;
                transition: transform $transitionDuration;

                &.layout-menu-container-inactive {
                    transform: translateX(-100%);
                }
            }

            .layout-topbar {
                left: 250px;
                transition: left $transitionDuration;
            }

            .layout-main {
                margin-left: 250px;
                transition: margin-left $transitionDuration;
            }

            .layout-footer {
                transition: padding-left $transitionDuration;
            }

            &.layout-static-inactive {
                .layout-topbar {
                    left: 0;
                    transition: left $transitionDuration;
                }

                .layout-menu-container {
                    transform: translateX(-100%);
                }

                .layout-main {
                    margin-left: 0;
                }

                .layout-footer {
                    padding-left: 20px;
                    transition: padding-left $transitionDuration;
                }
            }

            &.layout-static-inactive-restore {
                .layout-menu-container {
                    transition: none;
                }
            }
        }

        &.layout-overlay {
            .layout-topbar {
                left: 0;
                transition: left $transitionDuration;
            }

            .layout-menu-container {
                width: 250px;
                box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
                transition: transform $transitionDuration;

                &.layout-menu-container-inactive {
                    transform: translateX(-100%);
                }

                .layout-menu, .layout-profile {
                    border-right: 0 none;
                }
            }

            &.layout-overlay-active {
                .layout-topbar {
                    left: 250px;
                    transition: left $transitionDuration;
                }
            }
        }

        &.layout-slim {
            .layout-topbar {
                left: 60px;

                .layout-menu-button {
                    display: none;
                }
            }

            .layout-main {
                margin-left: 60px;
            }

            .layout-menu-container {
                padding: 0;
                height: 100%;
                width: 60px;
                overflow: visible;
                top: 0;
                position: fixed;

                .layout-menu-wrapper {
                    overflow: visible;
                    padding-bottom: 0px;
                }

                .layout-menu-logo {
                    overflow: hidden;

                    button {
                        img {
                            height: 36px;
                        }
                    }
                }

                .layout-profile {
                    .layout-profile-button {
                        text-align: center;
                        padding: 12px 0;

                        img {
                            width: 32px;
                        }

                        .layout-profile-userinfo,
                        .layout-profile-icon {
                            display: none;
                        }
                    }

                    .layout-profile-menu {
                        position: absolute;
                        overflow: auto;
                        top: 0;
                        left: 59px;
                        min-width: 250px;
                        max-height: 450px;
                        display: none;
                        border-left: 4px solid $topbarBgColor;
                        padding: 8px 12px;
                        @include border-radius-right($borderRadius);
                        box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);

                        &:before, &:after {
                            bottom: 100%;
                            border: solid transparent;
                            content: " ";
                            height: 0;
                            width: 0;
                            position: absolute;
                            pointer-events: none;
                            left: 210px;
                        }

                        &:before {
                            border-color: rgba(216, 216, 220, 0);
                            border-bottom-color: $topbarMenuBorderColor;
                            border-width: 10px;
                            margin-left: -10px;
                        }

                        &:after {
                            border-color: rgba(244, 244, 249, 0);
                            border-bottom-color: $topbarMenuBgColor;
                            border-width: 9px;
                            margin-left: -9px;
                        }

                        li {
                            button {
                                padding: 8px 12px;
                                transition: background-color $transitionDuration;
                            }
                        }
                    }

                    &.layout-profile-active {
                        .layout-profile-menu {
                            display: block;
                        }
                    }
                }

                .layout-menu {
                    padding: 0;

                    li.active-menuitem {
                        > ul {
                            display: block;
                        }
                    }

                    > li {
                        position: relative;
                        margin-bottom: 0;

                        &.active-menuitem {
                            > a,
                            > .p-link {
                                background-color: $horizontalMenuitemActiveBgColor;
                                @include border-radius-right(0);

                                .layout-menuitem-text,
                                .layout-menuitem-icon,
                                .layout-submenu-toggler {
                                    color: $horizontalMenuitemActiveTextColor;
                                }
                            }
                        }

                        > div {
                            display: none;
                        }

                        > a,
                        > .p-link {
                            display: block;
                            text-align: center;
                            padding-left: 0;
                            padding-right: 0;
                            padding-top: 19px;
                            padding-bottom: 19px;
                            border-radius: 0;

                            .layout-menuitem-icon {
                                font-size: 20px;
                                margin-right: 0;
                            }

                            .layout-menuitem-text, .layout-submenu-toggler {
                                display: none;
                            }

                            &:hover + .layout-menu-tooltip {
                                display: block;
                            }
                        }

                        > ul {
                            position: absolute;
                            overflow: auto;
                            top: 0;
                            left: 59px;
                            min-width: 250px;
                            max-height: 450px;
                            display: none;
                            border-left: 4px solid $topbarBgColor;
                            padding: 8px 12px;
                            @include border-radius-right($borderRadius);
                            box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);

                            li {
                                a,
                                .p-link {
                                    padding: 8px 12px;
                                    display: block;
                                }

                                ul {
                                    width: 100%;
                                    box-shadow: none;

                                    li {
                                        a,
                                        .p-link {
                                            padding-left: 28px;
                                        }

                                        li {
                                            a,
                                            .p-link {
                                                padding-left: 44px;
                                            }

                                            li {
                                                a,
                                                .p-link {
                                                    padding-left: 60px;
                                                }

                                                li {
                                                    a,
                                                    .p-link {
                                                        padding-left: 76px;
                                                    }

                                                    li {
                                                        a,
                                                        .p-link {
                                                            padding-left: 92px;
                                                        }

                                                        li {
                                                            a,
                                                            .p-link {
                                                                padding-left: 108px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.active-menuitem {
                            > a,
                            > .p-link {
                                &:hover + .layout-menu-tooltip {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            &.layout-menu-light {
                .layout-menu-container {
                    .layout-menu {
                        > li {
                            > ul {
                                background-color: $menuBgColor;
                            }
                        }
                    }

                    .layout-profile-menu {
                        background-color: $menuBgColor;
                    }
                }
            }

            &.layout-menu-dark {
                .layout-menu-container {
                    .layout-menu {
                        > li {
                            > ul {
                                background-color: $darkMenuBgColor;
                            }
                        }
                    }

                    .layout-profile-menu {
                        background-color: $darkMenuBgColor;
                    }
                }
            }
        }

        &.layout-horizontal {
            .layout-topbar {
                left: 0;

                .layout-topbar-logo {
                    display: inline-block;
                }

                .layout-menu-button {
                    display: none;
                }
            }

            .layout-menu-container {
                width: 100%;
                height: 46px;
                top: 60px;
                z-index: 99;
                position: fixed;
                padding: 5px 12px;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.10);

                .layout-menu-wrapper {
                    overflow: visible;
                }

                .layout-menu-logo {
                    display: none;
                }

                .layout-profile {
                    border-bottom: 0 none;
                    display: inline-block;
                    position: absolute;
                    right: 20px;
                    top: 0;

                    .layout-profile-button {
                        padding: 0;
                        margin-top: 2px;

                        img {
                            width: 32px;
                        }

                        .layout-profile-userinfo,
                        .layout-profile-icon {
                            display: none;
                        }

                        &:hover {
                            background-color: transparent;
                        }
                    }

                    .layout-profile-menu {
                        position: absolute;
                        background-color: $topbarMenuBgColor;
                        border: 1px solid $topbarMenuBorderColor;
                        right: -20px;
                        top: 50px;
                        min-width: 250px;
                        padding: 8px 12px;
                        border-radius: $borderRadius;
                        animation-duration: $transitionDuration;
                        box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);

                        &:before, &:after {
                            bottom: 100%;
                            border: solid transparent;
                            content: " ";
                            height: 0;
                            width: 0;
                            position: absolute;
                            pointer-events: none;
                            left: 210px;
                        }

                        &:before {
                            border-color: rgba(216, 216, 220, 0);
                            border-bottom-color: $topbarMenuBorderColor;
                            border-width: 10px;
                            margin-left: -10px;
                        }

                        &:after {
                            border-color: rgba(244, 244, 249, 0);
                            border-bottom-color: $topbarMenuBgColor;
                            border-width: 9px;
                            margin-left: -9px;
                        }

                        li {
                            button {
                                padding: 8px 12px;
                                color: $menuitemTextColor;
                                transition: background-color $transitionDuration;

                                i {
                                    color: $menuitemIconColor;
                                }

                                &:hover {
                                    background-color: $menuitemHoverBgColor;
                                }
                            }
                        }
                    }

                    &.layout-profile-active {
                        .layout-profile-menu {
                            display: block;
                        }
                    }
                }

                .layout-menu {
                    margin: 0;
                    padding: 0;

                    li.active-menuitem {
                        > ul {
                            display: block;
                        }
                    }

                    > li {
                        display: inline-block;
                        width: auto;
                        padding: 0;
                        position: relative;
                        margin-bottom: 0;

                        &.active-menuitem {
                            > a,
                            > .p-link {
                                background-color: $horizontalMenuitemActiveBgColor;
                                @include border-radius-bottom(0);

                                .layout-menuitem-text,
                                .layout-menuitem-icon,
                                .layout-submenu-toggler {
                                    color: $horizontalMenuitemActiveTextColor;
                                }
                            }
                        }

                        > div {
                            display: none;
                        }

                        > a,
                        > .p-link {
                            display: block;
                            padding: 8px 12px;

                            i {
                                position: static;
                                margin-top: 0;
                                vertical-align: middle;
                            }
                        }

                        > ul {
                            display: none;
                            top: 33px;
                            left: 0;
                            min-width: 250px;
                            position: absolute;
                            margin: 0;
                            padding: 8px 12px;
                            max-height: 450px;
                            overflow: auto;
                            border-top: 4px solid $topbarBgColor;
                            @include border-radius-bottom($borderRadius);
                            box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);

                            > li {
                                a,
                                .p-link {
                                    padding: 8px 12px;

                                    .layout-menuitem-icon {
                                        margin-right: 8px;
                                    }
                                }

                                li {
                                    a,
                                    .p-link {
                                        padding-left: 28px;
                                    }

                                    li {
                                        a,
                                        .p-link {
                                            padding-left: 44px;
                                        }

                                        li {
                                            a,
                                            .p-link {
                                                padding-left: 60px;
                                            }

                                            li {
                                                a,
                                                .p-link {
                                                    padding-left: 76px;
                                                }

                                                li {
                                                    a,
                                                    .p-link {
                                                        padding-left: 92px;
                                                    }

                                                    li {
                                                        a,
                                                        .p-link {
                                                            padding-left: 108px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .layout-main {
                padding-top: 106px;
            }

            &.layout-menu-light {
                .layout-menu-container {
                    border-bottom: $menuBorder;

                    .layout-menu-wrapper {
                        border-right: 0 none;
                    }

                    .layout-menu {
                        > li {
                            > ul {
                                background-color: $menuBgColor;
                            }
                        }
                    }
                }
            }

            &.layout-menu-dark {

                .layout-menu-container {
                    border-bottom: $darkMenuBorder;

                    .layout-profile {
                        border-right: 0 none;
                    }

                    .layout-menu {
                        border-right: 0 none;

                        > li {
                            > ul {
                                background-color: $darkMenuBgColor;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 896px) {
    .layout-wrapper {
        .layout-topbar {
            left: 0;
        }

        .layout-menu-container {
            top: 0;
            width: 250px;
            transform: translateX(-100%);
            transition: transform $transitionDuration;

            &.layout-menu-container-inactive {
                transform: translateX(-100%) !important;
            }
        }

        .layout-main {
            margin-left: 0;
            transition: margin-left $transitionDuration;
        }

        &.layout-mobile-active {
            .layout-menu-container {
                transform: translateX(0);
            }

            .layout-mask {
                display: block;
            }
        }
    }

    body.blocked-scroll {
        overflow: hidden;
    }
}
