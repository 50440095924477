.wizard-body {
    background: url("../../images/extensions/background.jpg") center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    .wizard-header {
        padding: 24px 10%;

        img {
            height: 40px;
        }
    }

    .wizard-content {
        height: calc(100vh - 100px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .wizard-card {
            border-radius: 4px;
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.24);
            background-color: #303f46;
            width: 65%;

            .wizard-card-header {
                .wizard-card-header-banner {
                    display: flex;
                    height: 110px;

                    .banner-logo {
                        img {
                            height: 100%;
                        }
                    }

                    .banner-image {
                        position: relative;
                        display: none;

                        h1 {
                            color: #ffffff;
                            font-size: 21px;
                            font-weight: 500;
                            position: absolute;
                            left: 24px;
                            top: 25px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                        }

                        &.active-banner {
                            display: block;
                        }
                    }
                }

                .wizard-card-tabs {
                    background-color: #405965;
                    display: flex;
                    justify-content: space-between;

                    .wizard-card-tab {
                        color: #aac8d6;
                        font-size: 13px;
                        cursor: pointer;
                        padding: 15px 10%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        text-align: center;

                        &:hover {
                            background-color: #4e6e7d;
                            @include transition(background-color .3s);
                        }

                        &.selected-tab {
                            color: #ffffff;
                            @include transition(color .3s);
                        }
                    }
                }
            }

            .wizard-card-content {
                padding: 25px 30px;
                min-height: 475px;

                .wizard-forms-wrapper {
                    .wizard-forms {
                        .form-label {
                            color: #ffffff;
                            font-size: 13px;
                            margin-bottom: 6px;
                            margin-top: 24px;
                            display: block;
                        }

                        .form-inline-label {
                            color: #ffffff;
                            font-size: 13px;
                            margin-bottom: 2px;
                        }
                    }
                }

                .wizard-button {
                    padding: 0 10px;
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;

                    .p-button {
                        background-color: #405965;
                        border: none;
                        border-radius: 4px;
                        padding: 6px 30px;
                        font-size: 13px;
                        font-weight: 600;

                        &:hover {
                            background-color: #4b6a79;
                            @include transition(background-color .3s);
                        }
                    }
                }

                &.register {
                    display: none;

                    .warning {
                        display: flex;
                        align-items: center;

                        >i {
                            color: #e0284f;
                            margin-right: 10px;
                            font-size: 22px;
                        }

                        >p {
                            color: #ffffff;
                            margin: 0;
                        }
                    }

                    .wizard-forms-wrapper {
                        .wizard-forms {
                            padding: 0 10px;

                            input {
                                width: 100%;
                                border: solid 1px #527382;
                                background-color: #405965;
                                color: #ffffff;
                            }

                            .p-dropdown {
                                border: solid 1px #527382;
                                width: 100%;

                                .p-inputtext {
                                    background-color: #405965;
                                    color: #ffffff;
                                    @include border-radius(2px);
                                }

                                .p-dropdown-trigger {
                                    background-color: #405965;
                                    color: #ffffff;
                                    @include border-radius(2px);
                                }
                            }

                            .calendar {
                                .form-label {
                                    margin-top: 35px;
                                }

                                span {
                                    width: 100%;
                                    display: flex;

                                    input {
                                        width: 100%;
                                        border: solid 1px #527382;
                                        background-color: #405965;
                                        color: #ffffff;

                                    }
                                }
                            }
                        }
                    }

                    .wizard-button {
                        margin-top: 114px;
                    }
                }

                &.tier {
                    display: none;

                    .wizard-tier-cards {
                        margin-bottom: 24px;

                        .wizard-tier-card {
                            background-color: #405965;
                            border-radius: 4px;
                            padding: 0;
                            @include border-radius(4px);
                            cursor: pointer;
                            min-width: 200px;

                            .wizard-tier-card-header {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-image: linear-gradient(to bottom, #5d8191, #4f707f);
                                padding: 10px 20px;
                                margin-bottom: 10px;
                                box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
                                @include border-radius-top(4px);


                                img {
                                    height: 75px;
                                }
                            }

                            .wizard-tier-card-content {
                                .title {
                                    >h1 {
                                        color: #ffffff;
                                        font-size: 21px;
                                        font-weight: 500;
                                        margin: 0;
                                        padding-left: 5px;
                                        border-left: 7px solid #d6619b;
                                    }

                                    >span {
                                        color: #ffffff;
                                        opacity: 0.7;
                                        font-size: 14px;
                                        margin-left: 12px;
                                        margin-top: 5px;
                                    }
                                }

                                >ul {
                                    padding: 24px 12px;
                                    list-style-type: none;
                                    margin: 0;

                                    >li {
                                        display: flex;
                                        align-items: center;
                                        padding: 7px 0;

                                        >i {
                                            font-size: 20px;
                                            color: #74909d;
                                            margin-right: 6px;
                                        }

                                        >p {
                                            margin: 0;
                                            color: #74909d;
                                        }

                                        &.active-list-item {

                                            i,
                                            p {
                                                color: #ffffff;
                                            }
                                        }
                                    }
                                }
                            }

                            &.beginner {
                                .title {
                                    >h1 {
                                        border-color: #d6619b;
                                    }
                                }
                            }

                            &.professional {
                                .title {
                                    >h1 {
                                        border-color: #4dbc90;
                                    }
                                }
                            }

                            &.enterprise {
                                .title {
                                    >h1 {
                                        border-color: #48bdf1;
                                    }
                                }
                            }

                            &.active-tier-card {
                                background-color: #3c6071;
                                @include transition(background-color .3s);
                            }

                            &:hover {
                                background-color: #4a6675;
                                @include transition(background-color .3s);
                            }
                        }
                    }

                    .wizard-button {
                        margin-top: 56px;
                    }
                }

                &.payment {
                    padding: 0;
                    display: none;

                    .wizard-forms-wrapper {
                        padding: 24px 40px;

                        .wizard-forms {
                            width: 66%;

                            .customPanel {
                                width: 100%;
                                margin-left: 1em;

                                img {
                                    height: 35px;
                                    margin-right: 8px;
                                }

                                .p-radiobutton {
                                    margin-top: 8px;
                                }
                            }

                            input {
                                width: 100%;
                                border: solid 1px #527382;
                                background-color: #405965;
                                color: #ffffff;
                                margin-left: .5em;
                            }

                            .form-label {
                                margin-left: .5em;
                            }

                            >.form-label {
                                width: 100%;
                                display: inline-block;
                            }

                            .p-checkbox-label {
                                color: #ffffff;
                                font-size: 13px;
                            }
                        }
                    }

                    .wizard-button {
                        position: relative;
                        background-image: linear-gradient(to right, rgba(48, 63, 70, .15) 23%, rgba(116, 144, 157, .15) 60%);
                        padding: 24px 48px;
                        margin-top: 65px;

                        .order-summary {
                            display: none;
                            position: absolute;
                            left: 65px;
                            top: -8px;

                            p {
                                color: #ffffff;
                                font-size: 13px;
                                margin: 0;
                                margin-bottom: 12px;
                            }

                            h1 {
                                color: #ffffff;
                                font-size: 21px;
                                margin: 0;
                            }

                            span {
                                color: #aac8d6;
                                font-size: 13px;
                            }

                            &.selected-order {
                                display: block;
                            }
                        }
                    }
                }

                &.active-content {
                    display: block;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .wizard-body {
        .wizard-content {
            .wizard-card {
                width: 55%;
            }
        }
    }
}

@media (max-width: 992px) {
    .wizard-body {
        .wizard-content {
            height: auto;
            align-items: stretch;

            .wizard-card {
                width: 90%;
                overflow: hidden;

                .wizard-card-header {
                    .wizard-card-header-banner {
                        height: 150px;

                        .banner-image {
                            width: 100%;

                            img {
                                display: none;
                            }

                            h1 {
                                right: 10px;
                            }
                        }
                    }

                    .wizard-card-tabs {
                        .wizard-card-tab {
                            padding: 15px 5%;
                        }
                    }
                }

                .wizard-card-content {
                    max-height: 400px;
                    overflow: auto;

                    &.register {
                        .wizard-button {
                            margin-top: 24px;
                        }
                    }

                    &.tier {
                        .wizard-button {
                            margin-top: 24px;
                        }
                    }

                    &.payment {
                        .wizard-forms-wrapper {
                            .wizard-forms {
                                width: 100%;
                            }
                        }

                        .wizard-button {
                            margin-top: 24px;
                            padding: 40px 10px;
                            display: flex;
                            align-items: center;
                            flex-direction: column;

                            .order-summary {
                                position: relative;
                                top: auto;
                                left: auto;
                                width: 100%;
                                text-align: center;
                                margin-bottom: 20px;
                            }

                            .p-button {
                                height: 50px;
                            }
                        }
                    }
                }
            }
        }
    }
}