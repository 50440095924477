// Application styles
@use "../theme/theme-blue" as theme;
@use "../layout/layout-blue" as layout;

// ----------------------------------------------------------------------------
// References
// ----------------------------------------------------------------------------
// SASS @use (preferred)
// https://sass-lang.com/documentation/at-rules/use/
// SASS @import (phasing out)
// https://sass-lang.com/documentation/at-rules/import/

// CSS selectors
// https://www.w3schools.com/csSref/css_selectors.php

// ----------------------------------------------------------------------------
// App variables
// ----------------------------------------------------------------------------
$defaultEmptyMoneyText: '-.-';

.page-content {
    max-width: 700px;
    margin: 0 auto;
}

$revokedRowBackgroundColor: lightgrey;

tr.revoked>td {
    opacity: theme.$disabledOpacity;
    background-color: $revokedRowBackgroundColor;
}

div.revoked {
    opacity: theme.$disabledOpacity;
    background-color: $revokedRowBackgroundColor;
}

// ----------------------------------------------------------------------------
// DataTable
// ----------------------------------------------------------------------------
// TODO: Review this because original colors are too dark

// $highlightTextColor: $primaryTextColor;
// $highlightBg: $primaryColor;

// Alternative
// $shade700 is the default text. shade800 is a little bit darker
// $dataTableSelectedTextColor: theme.$shade800 !default;
// $dataTableSelectedHighlightBg: scale-color(theme.$primaryColor, $lightness: 80%) !default;

// .p-datatable .p-datatable-tbody>tr.p-highlight {
//     color: $dataTableSelectedTextColor;
//     background-color: $dataTableSelectedHighlightBg;
// }

// ----------------------------------------------------------------------------
// TopBar - topbar.scss
// ----------------------------------------------------------------------------
// PageTitle: Displayed always
// MenuButton: Only displayed when side menu is hidden on small screens

.layout-wrapper {
    .layout-topbar {
        display: flex;

        .layout-topbar-title {
            flex: 1 1 auto;
            min-width: 0;

            h1 {
                color: theme.$primaryTextColor;
                margin: 0px;
                font-size: 24px;

                position: relative;
                top: 50%;
                transform: translateY(-50%);

                text-align: left;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .layout-menu-button {
            display: none;
            margin-right: 16px;
        }
    }
}

@media screen and (max-width: 896px) {
    .layout-wrapper {
        .layout-topbar {
            padding-left: 8px;

            .layout-menu-button {
                display: inline-block;
            }
        }
    }
}

// ----------------------------------------------------------------------------
// Footer - footer.scss
// ----------------------------------------------------------------------------

.footer-icons {
    .p-link {
        color: theme.$textSecondaryColor;
        font-size: 1.5rem;
        margin-right: 16px;
        margin-top: 6px;
        display: inline-block;
        transition: color theme.$transitionDuration;

        &:hover {
            color: theme.$textColor;
        }
    }
}

// ----------------------------------------------------------------------------
// Menu - menu.scss
// ----------------------------------------------------------------------------
// Hack to correct menu separators not being rendered

ul.layout-menu li.layout-root-menuitem {
    border-top: 1px solid #E0E0E0;
}

ul.layout-menu li.layout-root-menuitem:first-child {
    border-top: none;
}

// Other hacks until menu is redesigned

.layout-wrapper .layout-menu-container .layout-menu>li {
    margin-bottom: 6px;
}

.layout-wrapper .layout-menu-container .layout-menu li .p-link .layout-submenu-toggler {
    margin-right: 8px;
    vertical-align: middle;
}

// ----------------------------------------------------------------------------
// Other
// ----------------------------------------------------------------------------

// TODO: Review if this is needed, was used with messages lib (removed)
.toast-messages {
    // If two Toast components share the same position, then
    // they are rendered on different z-index. Latest Toast
    // that renders a message moves all messages to the top,
    // hiding the ones below. Until we find a way to merge
    // the toast messages, we will render them in different
    // positions. Note that position=top-center is hard to
    // use because it completely hides the content below.
    background-color: none;
}

div.execute-cancel-buttons {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
}

.not-focused-search-input {
    input {
        border: none;
        background-color: transparent;
        // background-color: red;
    }
}

.unstyled-list {
    // ul {
    margin: 0;
    padding: 0;
    list-style: none;
    // }
}

.revoked-amount {
    text-decoration: line-through;
}

// Customize Panel from 'primereact/panel'
// When Panel.header null or "", header is not displayed,
// but the panel-content top border is not rendered either.
.no-header-panel {
    .p-panel-content {
        // Required to display border box
        border-top: 1px solid #dee2e6;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }
}

// Customize  DataTable from 'primereact/datatable';
// When DataTable.header is null, "" or hidden, the datatable.thead borders are
// not rendered. We force the display of a top border of the table element below.

// hide-table-header
.p-datatable.hide-table-header>.p-datatable-header {
    display: none;
}

.p-datatable.hide-table-header>.p-datatable-wrapper>table>thead {
    tr:first-child th {
        border-width: 1px 0 1px 0;
    }
}

// hide-columns-header
.p-datatable.hide-columns-header>.p-datatable-wrapper>table>thead {
    display: none;
}

.p-datatable.hide-columns-header>.p-datatable-wrapper>table>tbody {
    tr:first-child td {
        border-width: 1px 0 1px 0;
    }
}

// hide-columns-footer
// TODO: There might be issues with this setup when nesting tables
// Check implementation of hide-columns-header
.p-datatable.hide-columns-footer {
    .p-datatable-table tfoot {
        display: none;
    }
}

// hide-columns-footer
// TODO: There might be issues with this setup when nesting tables
// Check implementation of hide-columns-header
.p-treetable.hide-table-header {
    .p-treetable-header {
        display: none;
    }

    .p-treetable-table {
        thead tr:first-child th {
            border-width: 1px 0 1px 0;
        }
    }
}

// -----------------------------------------------------------------------------
// Custom controls
// -----------------------------------------------------------------------------

.user-input,
.person-input {
    width: 100%;

    ul {
        width: 100%;
        // Prevent multiple persons from being displayed stacked, resizing the input
        // control vertically. This would only make sense in a 'persons-input' that
        // would be actually used to input multiple persons. Right now 'multiple' is
        // used in the component only to inherit the Chips' output functionality.
        flex-wrap: nowrap;
    }
}

// -----------------------------------------------------------------------------
// FieldValueGroup
// -----------------------------------------------------------------------------

.panel-button {

    // Panel that acts as a button: When clicked or
    // pressed spacebar or return, onClick triggers.
    &:hover {
        cursor: pointer;
        // background-color: #eeeeee;
    }
}

// TODO: check if this is needed or another variable would do
$dataBgColor: #ffffff;

.field-value-group {
    border-radius: 8px;
    background-color: $dataBgColor;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            border-bottom: 1px solid theme.$dividerColor;
            padding: 0;
            margin: 0;

            .grid {
                padding: 0;
                margin: 0;
            }

            .data {
                padding: 1em;
            }

            .label {
                font-size: 16px;
            }

            .value {
                font-size: 16px;
                color: theme.$textSecondaryColor;
            }

            .action {

                i,
                button {
                    float: right;
                }
            }

            &:last-child {
                border: 0;
            }
        }
    }
}

.name-since-until {
    .date-part {
        color: lightgray;
    }
}

// -----------------------------------------------------------------------------
// Text styles
// -----------------------------------------------------------------------------

.crossed-out {
    text-decoration: line-through;
}

// -----------------------------------------------------------------------------
// Table / DataTable / TreeTable
// -----------------------------------------------------------------------------

.split-table-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.table-actions-header {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
}

.table-vertical-align-top {
    td {
        vertical-align: top;
    }
}

.p-datatable.table-expandable-details {

    .p-datatable-tbody>tr {
        background: theme.$tableHeaderBg;
    }

    tr.p-datatable-row-expansion>td {
        // Align detail columns with master summary columns
        padding: 0;

        .p-datatable-thead {
            // Hide detail column headers, because they match those of summary columns
            display: none;
        }

        .p-datatable-tbody>tr {
            // Unset footer/header like
            background: #ffffff;
        }
    }
}

.p-datatable .p-datatable-thead>tr>th.table-column-2,
.p-datatable .p-datatable-tbody>tr>td.table-column-2,
.p-datatable .p-datatable-tfoot>tr>td.table-column-2 {
    width: 2em;
}

.p-datatable .p-datatable-thead>tr>th.table-column-3,
.p-datatable .p-datatable-tbody>tr>td.table-column-3,
.p-datatable .p-datatable-tfoot>tr>td.table-column-3 {
    width: 3em;
}

.p-datatable .p-datatable-thead>tr>th.table-column-4,
.p-datatable .p-datatable-tbody>tr>td.table-column-4,
.p-datatable .p-datatable-tfoot>tr>td.table-column-4 {
    width: 4em;
}

.p-datatable .p-datatable-thead>tr>th.table-column-5,
.p-datatable .p-datatable-tbody>tr>td.table-column-5,
.p-datatable .p-datatable-tfoot>tr>td.table-column-5 {
    width: 5em;
}

.p-datatable .p-datatable-thead>tr>th.table-column-6,
.p-datatable .p-datatable-tbody>tr>td.table-column-6,
.p-datatable .p-datatable-tfoot>tr>td.table-column-6 {
    width: 6em;
}

.p-datatable .p-datatable-thead>tr>th.table-column-8,
.p-datatable .p-datatable-tbody>tr>td.table-column-8,
.p-datatable .p-datatable-tfoot>tr>td.table-column-8 {
    width: 8em;
}

.p-datatable .p-datatable-thead>tr>th.table-column-left,
.p-datatable .p-datatable-tbody>tr>td.table-column-left,
.p-datatable .p-datatable-tfoot>tr>td.table-column-left {
    text-align: left;

    .p-column-header-content {
        justify-content: left;
    }

}

.p-datatable .p-datatable-thead>tr>th.table-column-center,
.p-datatable .p-datatable-tbody>tr>td.table-column-center,
.p-datatable .p-datatable-tfoot>tr>td.table-column-center {
    text-align: center;

    .p-column-header-content {
        justify-content: center;
    }
}

.p-datatable .p-datatable-thead>tr>th.table-column-right,
.p-datatable .p-datatable-tbody>tr>td.table-column-right,
.p-datatable .p-datatable-tfoot>tr>td.table-column-right {
    text-align: right;

    .p-column-header-content {
        justify-content: right;
    }
}

.p-datatable .p-datatable-thead>tr>th.table-column-date,
.p-datatable .p-datatable-tbody>tr>td.table-column-date,
.p-datatable .p-datatable-tfoot>tr>td.table-column-date,
.p-treetable .p-treetable-thead>tr>th.table-column-date,
.p-treetable .p-treetable-thead>tr>td.table-column-date,
.p-treetable .p-treetable-tbody>tr>td.table-column-date {
    width: 8em;
    min-width: 8em;
    max-width: 8em;
    text-align: center;

    .p-column-header-content {
        justify-content: center;
    }
}

.p-datatable .p-datatable-thead>tr>th.table-column-date-left,
.p-datatable .p-datatable-tbody>tr>td.table-column-date-left,
.p-datatable .p-datatable-tfoot>tr>td.table-column-date-left,
.p-treetable .p-treetable-thead>tr>th.table-column-date-left,
.p-treetable .p-treetable-thead>tr>td.table-column-date-left,
.p-treetable .p-treetable-tbody>tr>td.table-column-date-left {
    width: 8em;
    min-width: 8em;
    max-width: 8em;
    text-align: left;

    .p-column-header-content {
        justify-content: left;
    }
}

.p-datatable .p-datatable-thead>tr>th.table-column-datetime,
.p-datatable .p-datatable-tbody>tr>td.table-column-datetime,
.p-datatable .p-datatable-tfoot>tr>td.table-column-datetime {
    width: 12em;
    min-width: 12em;
    max-width: 12em;
    text-align: center;

    .p-column-header-content {
        justify-content: center;
    }
}

.p-datatable .p-datatable-thead>tr>th.table-column-datetime-left,
.p-datatable .p-datatable-tbody>tr>td.table-column-datetime-left,
.p-datatable .p-datatable-tfoot>tr>td.table-column-datetime-left {
    width: 12em;
    min-width: 12em;
    max-width: 12em;
    text-align: left;

    .p-column-header-content {
        justify-content: left;
    }
}

.p-datatable .p-datatable-thead>tr>th.table-column-year-month,
.p-datatable .p-datatable-tbody>tr>td.table-column-year-month,
.p-datatable .p-datatable-tfoot>tr>td.table-column-year-month,
.p-treetable .p-treetable-thead>tr>th.table-column-year-month,
.p-treetable .p-treetable-thead>tr>td.table-column-year-month,
.p-treetable .p-treetable-tbody>tr>td.table-column-year-month {
    width: 6rem;
    min-width: 6rem;
    max-width: 6rem;
    text-align: center;

    .p-column-header-content {
        justify-content: center;
    }
}



// table-column-money ---------------------------------------------------------

.p-datatable .p-datatable-thead,
.p-treetable .p-treetable-thead {

    tr>th.table-column-money {
        width: 10em;
        text-align: right;

        .p-column-header-content {
            justify-content: right;
        }
    }
}

.p-datatable .p-datatable-tbody,
.p-datatable .p-datatable-tfoot,
.p-treetable .p-treetable-tbody,
.p-treetable .p-treetable-tfoot {

    tr>td.table-column-money {
        width: 10em;
        text-align: right;
    }

    // Display empty symbol when money value is null
    tr>td.table-column-money:empty::before {
        content: $defaultEmptyMoneyText;
    }
}

/*
.p-datatable .p-datatable-thead > tr > th.table-column-money-2x-header {
    width: 20em;
    text-align: center;
}

.p-datatable .p-datatable-thead > tr > th.table-column-status,
.p-datatable .p-datatable-tbody > tr > td.table-column-status,
.p-datatable .p-datatable-tfoot > tr > td.table-column-status {
    width: 10em;
    text-align: center;
}

.p-datatable .p-datatable-thead > tr > th.table-column-separator,
.p-datatable .p-datatable-tbody > tr > td.table-column-separator,
.p-datatable .p-datatable-tfoot > tr > td.table-column-separator {
    width: 30px;
}
*/

// table-column-button --------------------------------------------------------

.p-datatable .p-datatable-thead>tr>th.table-column-with-button,
.p-datatable .p-datatable-tbody>tr>td.table-column-with-button,
.p-datatable .p-datatable-tfoot>tr>td.table-column-with-button {

    // This because the height is set by the button itself
    padding-top: 5px;
    padding-bottom: 5px;

    .p-column-header-content {
        .p-column-title {
            display: flex;
            gap: 0.5rem;
            align-content: center;
            align-items: center;

        }
    }
}

.p-datatable .p-datatable-thead>tr>th.table-column-button,
.p-datatable .p-datatable-tbody>tr>td.table-column-button,
.p-datatable .p-datatable-tfoot>tr>td.table-column-button {
    width: 42px; // 5 + 32 + 32 + 5
    padding: 5px;
    max-width: 42px;
    min-width: 42px;
    text-align: center;

    .p-column-header-content {
        justify-content: center;
    }
}

.p-datatable .p-datatable-thead>tr>th.table-column-button-2,
.p-datatable .p-datatable-tbody>tr>td.table-column-button-2,
.p-datatable .p-datatable-tfoot>tr>td.table-column-button-2 {
    width: 76px; // 1 + 5 + 32 + 32 + 5 + 1
    padding: 5px;
    max-width: 76px;
    min-width: 76px;
    text-align: center;

    .p-column-header-content {
        justify-content: center;
    }
}

.p-datatable .p-datatable-thead>tr>th.table-column-no-padding,
.p-datatable .p-datatable-tbody>tr>td.table-column-no-padding,
.p-datatable .p-datatable-tfoot>tr>td.table-column-no-padding {
    padding: 0;
}

.s-disabled-color * {
    color: lightgrey;
    // background: whitesmoke;
}

// -----------------------------------------------------------------------------
// Input Controls
// -----------------------------------------------------------------------------

.p-datatable .p-datatable-thead>tr>th.table-money-input,
.p-datatable .p-datatable-tbody>tr>td.table-money-input,
.p-datatable .p-datatable-tfoot>tr>td.table-money-input {
    width: 10em;
    text-align: right;

    .p-column-header-content {
        justify-content: right;
    }

    input {
        // Equals 10em - (1em + 1em of padding)
        width: 8em;
        text-align: right;
    }
}

// -----------------------------------------------------------------------------
// Buttons
// -----------------------------------------------------------------------------

button.execute-button-single {
    float: right;
}

// -----------------------------------------------------------------------------
// Links
// -----------------------------------------------------------------------------

// https://github.com/remix-run/react-router/issues/1082
// https://stackoverflow.com/a/52061351

.disabled-link {
    pointer-events: none;
    // Warning: This could cause problems in some backgrounds
    color: lightgrey;
}

a[disabled] {
    pointer-events: none;
}


// -----------------------------------------------------------------------------
// _menu.scss
// -----------------------------------------------------------------------------

.layout-wrapper .layout-menu-container .layout-profile .layout-profile-menu {

    .p-avatar {
        margin-right: 10px;
    }

    li:first-child {
        padding-top: 14px;
    }

    .layout-profile-site {
        display: block;
        font-weight: 700;
    }
}

.login-page {
    display: flex;
    justify-content: center;
    padding-top: 4rem;
    box-sizing: border-box;
}

.login-main {
    width: 350px;

    background: white;
    border-radius: 8px;
    border-style: solid;
    border-color: theme.$shade500;
    border-width: thin;
    overflow: hidden;

    div.login-main-logo {
        padding: 2rem;
        background: theme.$primaryColor;

        img {
            width: 100%;
            padding: 16px;
        }

    }

    div.login-main-input {
        padding: 3rem;
        padding-bottom: 1rem;
    }

    div.login-main-contact {
        padding: 3rem;
        padding-top: 1rem;

        // .p-button {
        //     margin-top: 12px;
        // }
    }

}

// .login-footer {

//     width: 100%;
//     bottom: 0;
//     position: absolute;

//     display: flex;
//     gap: 1rem;
//     flex-wrap: nowrap;
//     flex-direction: column;
//     justify-content: center;

//     padding: 20px;
//     background-color: layout.$footerBgColor;
//     border-top: 1px solid layout.$footerBorderColor;

//     div.contact {
//         display: flex;
//         gap: 1rem;
//         flex-wrap: wrap;
//         flex-direction: row;
//         align-items: center;
//         justify-content: center;
//     }

//     div.copyright {
//         display: flex;
//         justify-content: center;
//     }
// }

.service-dashboard {

    div.contact>div {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        flex-direction: column;
    }

}

.custom-filter-button {
    .p-column-filter-row {
        .p-column-filter-menu-button {
            display: none;
        }

        .p-column-filter-clear-button {
            display: none;
        }
    }
}

.vertical-multiselect.p-multiselect {

    // use showClear={false} on MultiSelect
    // .p-multiselect-clear-icon {
    //     display: none;
    // }

    .p-multiselect-label {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        .p-multiselect-token {
            margin-top: 0.5rem;
            width: fit-content;
        }
    }
}

// -----------------------------------------------------------------------------
// Constrained
// -----------------------------------------------------------------------------

@media screen and (max-width: 400px) {
    .hide-constrained-400 {
        display: none;
    }

    .layout-wrapper .layout-main .layout-content {
        padding: 8px;
    }
}

@media screen and (max-width: 700px) {
    .hide-constrained-700 {
        display: none;
    }
}

// -----------------------------------------------------------------------------
// Document: Transaction, Receipt, TokenPack
// -----------------------------------------------------------------------------

.document-action-header {
    display: flex;
    flex-wrap: wrap;

    div.title {
        gap: 0.5rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-right: 1rem;
    }

    div.actions {
        gap: 0.5rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        flex-grow: 1;
    }

    span.spacer {
        flex-grow: 1;
    }

    button {
        flex-shrink: 0;
    }
}

// -----------------------------------------------------------------------------
// Transaction
// -----------------------------------------------------------------------------

.transaction-item {
    @extend .page-content;
}

// -----------------------------------------------------------------------------
// Receipt
// -----------------------------------------------------------------------------

.receipt-item {
    @extend .page-content;
}

// TODO: Check breakpoints: 600,700,800

.issue-receipt {
    @extend .page-content;
}

@media screen and (max-width: 600px) {

    .issue-receipt {

        .issue-receipt-cancelations {

            .p-datatable-thead>tr>th.width-constrained,
            .p-datatable-tbody>tr>td.width-constrained,
            .p-datatable-tfoot>tr>td.width-constrained {
                display: none;
            }
        }

    }
}


.p-datatable .p-datatable-tbody>tr>td.table-column-button-expander {
    width: 40px;
    margin-right: 0;
    padding-right: 0;
    padding-left: 5px;

}

// -----------------------------------------------------------------------------
// LocalPayments
// -----------------------------------------------------------------------------

.local-payments-dashboard {
    @extend .page-content;
}

// -----------------------------------------------------------------------------
// CashRegister
// -----------------------------------------------------------------------------

.cash-register-list {
    .p-datatable-tbody>tr>td {
        padding: 0;
    }

    .p-datatable-tbody>tr.p-datatable-emptymessage>td {
        padding: 1rem 1rem;
    }

}

.cash-register {
    @extend .page-content;
}


$cashRegisterControlButtonWidth: 150px;
$cashRegisterControlButtonHeight: 88px;

.cash-register-control {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;

    div.text-as-button {
        width: $cashRegisterControlButtonWidth;
        height: $cashRegisterControlButtonHeight;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
        border-radius: 4px;
        gap: 0.25rem;
        font-size: 1rem;
        padding: 1rem 0.75rem;

        border: 1px solid theme.$shade400;
    }

    button {
        width: $cashRegisterControlButtonWidth;
        height: $cashRegisterControlButtonHeight;
    }

    button.long-text-label {
        span.p-button-icon {
            margin-right: 15px;
        }

        span.p-button-label {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-align: left;
        }
    }

    span.one-liner {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
    }
}

.cash-register-sessions {
    @extend .page-content;
}

// -----------------------------------------------------------------------------
// CashSession
// -----------------------------------------------------------------------------

.cash-session {
    @extend .page-content;
}

.cash-session-list {

    div.amounts-template {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-direction: column;
        gap: 2rem;
    }

}

.cash-session-detail {
    .p-tabview-panels {
        padding: 0;
    }
}

// -----------------------------------------------------------------------------
// SystemInformation
// -----------------------------------------------------------------------------

div.system-info-block {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-bottom: 1em;
}

div.system-info-header {
    width: 80px;
    flex-shrink: 0;
}

div.system-info-cell {
    overflow: hidden;
    flex-direction: column;
}

div.system-info-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

// -----------------------------------------------------------------------------
// Badge
// -----------------------------------------------------------------------------

.p-badge.x-small {
    width: 0.75rem;
    height: 0.75rem;
    min-width: 0.75rem;
    min-height: 0.75rem;
}

.table-column-badge {
    @extend .table-column-no-padding;
    width: 0.75rem;
    min-width: 0.75rem;
    max-width: 0.75rem;
}



// -----------------------------------------------------------------------------
// FieldErrorMessage
// -----------------------------------------------------------------------------

.field-error-message {
    margin-left: 7px;
}

.field-error-message.p-warn {
    color: #cc8925;
}


// -----------------------------------------------------------------------------
// RegistrationsReport
// -----------------------------------------------------------------------------

.registrations-report-page {
    @extend .page-content;
}


// -----------------------------------------------------------------------------
// AccountingReport
// -----------------------------------------------------------------------------

$frozenColumnTreeTableRowHeight: 57px;
$frozenColumnTreeTableHeaderRowHeight: 63px;
// Header row is made higher to fit:
//  - WeekNumber
//  - WeekMonday
// when TimeUnit.WEEK is selected. Otherwise, 57px is perfect.

.p-treetable.accounting-report-table {

    .p-treetable-frozen-view .p-treetable-emptymessage td {
        // Prevent displaying empty message on the frozen column
        font-size: 0;
    }

    .p-treetable-scrollable-header-table .p-treetable-thead>tr {
        height: $frozenColumnTreeTableHeaderRowHeight;
    }

    .p-treetable-scrollable-body .p-treetable-tbody {

        tr {
            height: $frozenColumnTreeTableRowHeight;

            td.report-frozen-column {
                padding-left: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        tr.report-item-header {
            font-weight: 700;
            background-color: theme.$tableHeaderBg;
        }

        tr.report-item-detail {
            td button.p-treetable-toggler {
                display: none;
            }
        }
    }

}


// -----------------------------------------------------------------------------
// Person
// -----------------------------------------------------------------------------

.person-create {
    @extend .page-content;
    max-width: 500px;
}

.person-page {
    @extend .page-content;
}

// -----------------------------------------------------------------------------
// PersonRegistrations
// -----------------------------------------------------------------------------

.person-registration-table {

    thead>tr>th.relation-column,
    tbody>tr>td.relation-column {
        // This is not working...
        min-width: 6em;
        padding-left: 3px;
    }

    .p-treetable-tbody {

        tr.major-relation {
            td>button.p-treetable-toggler {
                margin-right: 0px;
            }
        }

        tr.minor-relation,
        tr.major-relation-no-details {
            td>button.p-treetable-toggler {
                display: none;
            }
        }
    }
}

// -----------------------------------------------------------------------------
// ReceiptsSearch
// -----------------------------------------------------------------------------

.receipts-search {
    @extend .page-content;

    .p-datatable .p-datatable-header {
        padding-bottom: 0px;
    }

    div.receipts-filter {
        display: flex;
        flex-wrap: wrap;

        .person {
            width: 100%;
        }

        .comment {
            width: 100%;

            input {
                width: 100%;
            }

        }

    }

    span.description-template {

        div.id {
            color: theme.$textSecondaryColor;
        }

        div.comment {

            display: flex;
            align-content: center;
            flex-wrap: wrap;
            align-items: center;
            gap: 1em;

            span.comment-text:empty::before {
                color: theme.$textSecondaryColor;
                content: 'Agregar comentario...';
            }
        }
    }
}

// -----------------------------------------------------------------------------
// FundEntry
// -----------------------------------------------------------------------------

.fund-entry-search {
    @extend .page-content;
}

.fund-entry-create-button {
    width: $cashRegisterControlButtonWidth;
    // height: $cashRegisterControlButtonHeight * 0.75;
}

.fund-entry-card {
    width: 100%;
    padding: 12px;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 0.25rem;

    hr {
        margin: 4px 4px 4px 48px;
        width: 150px;
    }

    .colgroup {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;

        .col1 {
            width: 36px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                margin-top: 5px;
                font-size: 24px;
                font-weight: 100;

                span.p-badge {
                    height: 8px;
                    width: 8px;
                    min-width: unset;
                    line-height: unset;

                }
            }
        }

        .col2 {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            flex-grow: 1;
            padding-left: 12px;
        }

        .col3 {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            align-items: flex-end;
        }

    }

    div.type {
        color: theme.$shade800;
        font-size: 1rem;
        font-weight: bold;
    }

    div.type.revoked {
        text-decoration: line-through;
    }

    div.account {
        font-size: 1rem;
        color: theme.$shade600;
    }

    div.amount {
        font-size: 16px;
        font-weight: bold;
    }

    div.date {
        font-size: 14px;
        color: theme.$shade500;

    }

    div.counterpart {
        font-size: 1rem;
        color: theme.$shade600;
    }

    div.description {
        font-size: 1rem;
        color: theme.$shade600;

    }

    div.status {


        color: brown;
    }
}

.fund-entry-status-filter.input-panel {

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    hr {
        margin: 5px;
    }

    .filter-head {
        display: flex;
        gap: 1em;

        button {
            height: 16px;
            margin-right: 5px;
        }
    }

    label.checked {
        font-weight: 600;
    }

    div.disabled {
        opacity: theme.$disabledOpacity;
    }

}


// -----------------------------------------------------------------------------
// Addresses
// -----------------------------------------------------------------------------

div.address-value {
    white-space: pre-line;
}

// -----------------------------------------------------------------------------
// UserTransactionsBalance
// -----------------------------------------------------------------------------

.user-transactions-balance-master {
    @extend .page-content;
}

.user-transactions-balance-detail {
    @extend .page-content;
}

// -----------------------------------------------------------------------------
// Treasury
// -----------------------------------------------------------------------------

.treasury-cash-report {

    @extend .page-content;

    .p-treetable {

        tr.report-group {
            font-weight: 700;
            background-color: theme.$tableHeaderBg;
        }

        tr.report-item {
            td button.p-treetable-toggler {
                display: none;
            }

            div.name {
                font-weight: 500;
                margin-bottom: 5px;
            }

            div.status {
                // color: theme.$textSecondaryColor;
                color: theme.$shade500
            }
        }

        .p-treetable-tfoot {
            .report-time {
                font-weight: 400;
                color: theme.$textSecondaryColor;
            }
        }

    }

}

// ----------------------------------------------------------------------------
// Component: ReportMultiRelation
// ----------------------------------------------------------------------------

.p-multiselect-item-group:has(span.empty-minor-selector) {
    display: none;
}


// ----------------------------------------------------------------------------
// Commissions
// ----------------------------------------------------------------------------

.commission-list {
    @extend .page-content;
}

.commission-item {
    @extend .page-content;

    .commission-item-panels>.p-tabview-panels {
        // padding-left: 0;
        // padding-right: 0;
        padding: 0;
    }
}


.commission-item-summary {

    .p-datatable.summary-table {

        tr.report-item-header {
            font-weight: 700;
            background-color: theme.$tableHeaderBg;
        }
    }
}

.commission-next-payments {
    @extend .page-content;
}

.commission-template-list {

    tr.p-datatable-row-expansion>td {
        padding: 0;
    }

    div.template-name {
        // color: theme.$shade800;
        font-size: 1rem;
        font-weight: bold;
    }
}

.commission-pledge-create {
    @extend .page-content;

    .p-accordion-content {
        padding: 0px;
    }
}

.commission-pledge-item {
    @extend .page-content;
}

// .commission-pledge-list {}

// ----------------------------------------------------------------------------
// Tokens
// ----------------------------------------------------------------------------

.token-type-search {
    @extend .page-content;
}

.token-pack-create {
    @extend .page-content;
}

.token-pack-item {
    @extend .page-content;
}

.token-pack-search {
    @extend .page-content;

    div.search-filter {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .person {
            width: 100%;
        }

        .type-selector {
            margin-right: 10px;
        }

    }

    .token-pack-row {

        div.pack-description {
            font-size: 1rem;
            color: theme.$shade800;
            font-weight: bold;
        }

        // div.time-period {}

        div.issued-at {
            font-size: 1rem;
            color: theme.$shade500;
        }

    }
}

.token-use-search {
    @extend .page-content;

    div.search-filter {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .person {
            width: 100%;
        }

        .type-selector {
            margin-right: 10px;
        }

        .comment {
            width: 100%;

            input {
                width: 100%;
            }

        }

    }

    .token-use-row {

        div.pack-type-name {
            font-size: 1rem;
            color: theme.$shade800;
            font-weight: bold;
        }

        div.used-at {
            font-size: 1rem;
            color: theme.$shade500;
        }
    }
}

.token-use-create {
    // @extend .page-content;
    width: 500px;
}

.person-token-pack-list {

    div.token-pack {
        gap: 0.25rem;
        display: flex;
        flex-direction: column;

        div.pack-description {
            font-size: 1rem;
            color: theme.$shade800;
            font-weight: bold;
        }

        div.period {
            font-size: 1rem;
            color: theme.$shade600;
        }

        div.issued-at {
            font-size: 1rem;
            color: theme.$shade500;
        }
    }

}

div.token-pack-balance {
    display: flex;
    align-items: center;
    // justify-content: flex-start | center; // set by clients
    gap: 1rem;
}


// ----------------------------------------------------------------------------
// Events
// ----------------------------------------------------------------------------

.event-detail-list {
    @extend .page-content;

}

.date-filter.input-panel {

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    hr {
        margin: 5px;
    }

    .filter-group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

}

.event-detail-item {
    @extend .page-content;
}

.event-attendance-create {
    @extend .page-content;
    max-width: 400px;
}


// ----------------------------------------------------------------------------
// SinceUntilBlock
// ----------------------------------------------------------------------------

div.since-until-block {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    div#period {
        min-width: 10em;
    }

    .date-part {
        width: 9em
    }
}
